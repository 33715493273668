// import * as Sentry from '@sentry/sveltekit';
// import { building, dev, browser } from '$app/environment';

// if (!building && !dev && browser) {
// 	Sentry.init({
// 		enableTracing: true,
// 		tracesSampleRate: 1.0,

// 		// This sets the sample rate to be 10%. You may want this to be 100% while
// 		// in development and sample at a lower rate in production
// 		replaysSessionSampleRate: 0.1,

// 		// If the entire session is not sampled, use the below sample rate to sample
// 		// sessions when an error occurs.
// 		replaysOnErrorSampleRate: 1.0,
// 		integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
// 		ignoreErrors: [
// 			// Random plugins/extensions
// 			'top.GLOBALS',
// 			// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
// 			'originalCreateNotification',
// 			'canvas.contentDocument',
// 			'MyApp_RemoveAllHighlights',
// 			'http://tt.epicplay.com',
// 			"Can't find variable: ZiteReader",
// 			'jigsaw is not defined',
// 			'ComboSearch is not defined',
// 			'http://loading.retry.widdit.com/',
// 			'atomicFindClose',
// 			// Facebook borked
// 			'fb_xd_fragment',
// 			// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
// 			// reduce this. (thanks @acdha)
// 			// See http://stackoverflow.com/questions/4113268
// 			'bmi_SafeAddOnload',
// 			'EBCallBackMessageReceived',
// 			// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
// 			'conduitPage',
// 			'TypeError: Failed to fetch dynamically imported module: https://apply.xtendops.com/_app/immutable'
// 		]
// 	});
// }

/** @type {import('@sveltejs/kit').HandleClientError} */
export async function handleError({ error, event }) {
	const errorId = crypto.randomUUID();
	// example integration with https://sentry.io/
	// Sentry.captureException(error, { extra: { event, errorId } });

	try {
		await fetch(`/api/logger`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json'
			},
			body: JSON.stringify({
				action: 'hooksClient',
				error,
				event,
				errorId
			})
		});
	} catch (error) {
		console.error(error);
	}

	return {
		message: error.message,
		errorId
	};
}
